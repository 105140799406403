import useAppConfig from '@core/@app-config/useAppConfig'
import { getVuetify } from '@core/utils'
import themeConfig from '@themeConfig'
import { ref, watch } from '@vue/composition-api'

export function useLayout() {
  const handleBreakpointLayoutSwitch = () => {
    const $vuetify = getVuetify()
    const { appContentLayoutNav } = useAppConfig()
    const lgAndUpNav = ref(themeConfig.app.contentLayoutNav)
    watch(appContentLayoutNav, value => {
      if ($vuetify?.breakpoint.lgAndUp) lgAndUpNav.value = value
    })
    watch(
      () => $vuetify?.breakpoint.lgAndUp,
      value => {
        if (value) appContentLayoutNav.value = lgAndUpNav.value
        else appContentLayoutNav.value = 'vertical'
      },
      {
        immediate: true,
      },
    )
  }

  return {
    handleBreakpointLayoutSwitch,
  }
}

export const _ = null
