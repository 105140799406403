import trim from './modules/trim'
import auth from './modules/anth'

const directivesList = { auth, trim }

const directives = {
  install(app) {
    Object.keys(directivesList).forEach(key => {
      app.directive(key, directivesList[key])
    })
  },
}

export default directives
