<template>
  <layout-content-vertical-nav
    style="margin: 0!important;"
  >
    <slot></slot>
    <template #navbar="{ _, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <div class="flex-left align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3 mx12"
            color="#ffffff"
            @click="toggleVerticalNavMenuActive"
          >
            mdi-menu
          </v-icon>

          <div class="menu-body">
            <div v-for="item in menu" :key="item.id" class="pointer flex-center menu-body-item" :class="[activeRootMenuName === item.route ? 'active-menu' : '']" style="color: #ffffff" @click="jumpMenu(item)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="d-flex align-center right-row">
          <MessagePrompt />
          <Avatar />
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import { getVuetify } from '@core/utils'
import { ref, computed } from '@vue/composition-api'
import Avatar from '@/layouts/components/header/Avatar.vue'
import MessagePrompt from '@/layouts/components/header/MessagePrompt.vue'
import { useStore } from '@/hooks/useStore'
import { useRouter } from '@/hooks/useRouter'

export default {
  components: {
    Avatar,
    MessagePrompt,
    LayoutContentVerticalNav,
  },
  setup() {
    const $vuetify = getVuetify()
    const shallShowFullSearch = ref(false)
    const { menu, setSunMenu, flatteningSunMenu } = useStore('menu')
    const { route, router } = useRouter()

    const activeRootMenuName = computed(() => {
      const { name } = route.value

      // 获取当前路由原始菜单数据
      const active = (flatteningSunMenu.value || []).find(item => item.route === name)

      return active ? active.rootName : ''
    })

    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    function jumpMenu(item) {
      setSunMenu(item)
      if (item.children.length > 0) {
        router.push({ name: item.children[0].route })
      }
    }

    function init() {
      const { name } = route.value
      const active = (flatteningSunMenu.value || []).find(item => item.route === name)
      const data = menu.value.find(item => item.route === active.rootName)
      if (data) {
        setSunMenu(data)
      }
    }

    init()

    return {
      handleShallShowFullSearchUpdate,
      shallShowFullSearch,
      menu,
      jumpMenu,
      activeRootMenuName,
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}

.menu-body {
  height: 64px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 19px;

  &-item {
    height: 100%;
    padding: 0 20px;
    transition: all 0.2s ease-in-out;
  }

  .active-menu {
    background: rgba(145,85,253,0.08);
    box-shadow: inset 0 3px 0 0 #9155FD;
  }
}
</style>
