import { getCurrentInstance } from '@vue/composition-api'
import { isFunction } from '@prettyy/utils'

// eslint-disable-next-line no-new-func
export const deepCloneFunction = fn => new Function(`return ${fn.toString()}`)()
export function deepCopyWithFunc(obj) {
  if (typeof obj !== 'object') return
  const newObj = obj instanceof Array ? [] : {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') newObj[key] = deepCopyWithFunc(obj[key])
      else if (obj[key] instanceof Function) newObj[key] = deepCloneFunction(obj[key])
      else newObj[key] = obj[key]
    }
  }

  return newObj
}

export const isToday = date => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins && ins.$vuetify ? ins.$vuetify : null
}

//  滚动到底部
export const psScrollToBottom = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = scrollEl.scrollHeight
}

// 滚动到顶部
export const psScrollToTop = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = 0
}

export function asynchronousReturn(fun) {
  if (isFunction(fun)) {
    return new Promise((resolve, reject) => {
      const data = fun()
      if (data) resolve(data)
      else reject(data)
    })
  }
}

export function getUUID(len = 16) {
  let uuid = ''
  for (let i = 0; i < len; i++) {
    const random = (Math.random() * 16) | 0
    if (i === 8 || i === 12 || i === 16 || i === 20) uuid += '-'
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16)
  }

  return uuid
}

export const resolveIconName = iconName => iconName.replace(/([A-Z])/g, '-$1').toLowerCase()
