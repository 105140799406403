// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create()

axiosIns.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')
    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
    if (accessToken) config.headers['X-ACCESS-TOKEN'] = `${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => {
    console.log(3332)
    if ([401, 400].includes(response.data.status)) {
      localStorage.removeItem('accessToken')
      window.location.href = '/login'
    }

    return response
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
