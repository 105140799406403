<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template #activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="userData.user.avatar"
            :src="userData.user.avatar"
          ></v-img>
          <v-icon
            v-else
            color="primary"
            size="28"
          >
            mdi-account-outline
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="userData.user.avatar"
              :src="userData.user.avatar"
            ></v-img>
            <v-icon
              v-else
              color="primary"
              size="28"
            >
              mdi-account-outline
            </v-icon>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1 overflow">
            {{ userData.fullName || userData.user.username }}
          </span>
          <small class="text--disabled text-capitalize">{{ userData.role.join(',') }}</small>
        </div>
      </div>
      <v-divider class="my-2" />

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            mdi-logout-variant
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>退出</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import { useRouter } from '@/hooks/useRouter'
import { initialAbility } from '@/plugins/acl/config'
import { getCurrentInstance } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import { useMessage } from '@/hooks/useMessage'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const { alert } = useMessage()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const logoutUser = () => {
      // 发送退出事件
      useJwt.logout().then(() => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('userability')
        localStorage.clear()

        // Reset ability
        vm.$ability.update(initialAbility)

        alert('退出成功！', '提示', { confirmBtnText: '确认', type: 'error' }).then(() => {
          router.push({ name: 'auth-login' })
        })
      })
    }

    return {
      logoutUser,
      userData,
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
.overflow{
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
