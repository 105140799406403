import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosInsParam, jwtOverrideConfig) {
    this.axiosIns = axiosInsParam
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${accessToken}`
        }

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        if (response.data.status === 401) {
          localStorage.removeItem('accessToken')
          window.location.href = '/login'
        }

        return response
      },
      error => {
        const { response } = error
        if (response && response.status === 500 && response.data.status === 400 && response.data.message.indexOf('无效') !== -1) {
          localStorage.removeItem('accessToken')
          window.location.href = '/login'
        }

        return Promise.reject(error)
      },
    )
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  logout() {
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint)
  }

  ssoAuthUrl(...args) {
    return this.axiosIns.post(this.jwtConfig.ssoAuthUrlEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  getRoute(...args) {
    return this.axiosIns.get(this.jwtConfig.routeEndpoint, ...args)
  }

  getMenu(...args) {
    return this.axiosIns.get(this.jwtConfig.menuEndpoint, ...args)
  }
}
