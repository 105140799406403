import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import menu from './app/menu'

const noPersistence = ['app']

function omitKey(obj, keyToOmit) {
  // eslint-disable-next-line no-unused-vars
  const { [keyToOmit]: _, ...rest } = obj

  return rest
}

// 持久化插件
function persistencePlugin(config) {
  const { keyName = 'vuex', storage = 'localStorage' } = config

  return function (store) {
    const data = JSON.parse(window[storage].getItem(keyName) || '{}')
    if (JSON.stringify(data) !== '{}') store.replaceState(Object.assign(store.state, data))
    store.subscribe((mutation, state) => {
      let newData = { ...state }
      noPersistence.forEach(key => {
        newData = omitKey(newData, key)
      })
      window[storage].setItem(keyName, JSON.stringify(newData))
    })
  }
}
export const modules = {
  appConfig: appConfigStoreModule,
  app,
  menu,
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [persistencePlugin({ keyName: 'vuex', storage: 'localStorage' })],
})
