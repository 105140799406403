const auth = {
  inserted(el, binding) {
    const { value } = binding
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRoles = userData.role || []
    if (value === undefined) return
    if (Array.isArray(value) && value.length) {
      const hasPermission = value.some(item => userRoles.includes(item))
      if (!hasPermission) el.remove()
    } else if (!userRoles.includes(value)) el.remove()
  },
}

export default auth
