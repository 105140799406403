/**
 * 获取元素
 */
function getElement(el) {
  let inputEle = null
  const { tagName } = el
  if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
    inputEle = el
  } else {
    inputEle = el.querySelector('input') || el.querySelector('textarea')
  }

  return inputEle
}

// 创建事件
function dispatchEvent(el, type) {
  const evt = new Event(type, { bubbles: true, cancelable: true })
  el.dispatchEvent(evt)
}

const trim = {
  inserted(el) {
    const inputEle = getElement(el)
    if (!inputEle) return false
    const handler = event => {
      const newValue = event.target.value.trim()
      if (event.target.value !== newValue) {
        event.target.value = newValue
        dispatchEvent(event.target, 'input')
      }
    }

    el.inputEle = inputEle
    el._blurHandler = handler
    inputEle.addEventListener('blur', handler)
  },
  unbind(el) {
    const { inputEle } = el
    inputEle.removeEventListener('blur', el._blurHandler)
  },
}

export default trim
