import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import base from '@/router/base'
import { Message } from '@prettyy/ui'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/transfer-page',
  },
  ...base,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

function getRoute() {
  return new Promise(resolve => {
    useJwt.getRoute().then(response => {
      const role = response.data.data
      const routerData = []
      role.forEach(item => {
        const userRouter = {
          path: item.path,
          name: item.route,
          // eslint-disable-next-line global-require,import/no-dynamic-require
          component: res => require([`@/views${item.path}`], res),
          meta: { ...item.meta, pageTitle: item.pageTitle || item.name },
        }
        routerData.push(userRouter)
      })

      return resolve(routerData)
    })
  })
}

let flag = false
let timer = null
function judgeUpdate() {
  if (localStorage.getItem(useJwt.jwtConfig.storageVersionKeyName) !== useJwt.jwtConfig.version && !flag) {
    flag = true
    Message.success('版本已更新2秒后将自动刷新')
    localStorage.setItem(useJwt.jwtConfig.storageVersionKeyName, useJwt.jwtConfig.version)
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      flag = false
      window.location.reload()
      clearTimeout(timer)
    }, 2000)
  }
}

router.beforeEach(async (to, _, next) => {
  const userData = localStorage.getItem('userData')
  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  // 如果是登录直接放行
  if (to.path === '/login') {
    localStorage.clear()

    return next()
  }

  // 判断是否登录
  if (!isLoggedIn) {
    return next({ name: 'auth-login', replace: true })
  }

  // 登录是否有效
  if (isLoggedIn) {
    judgeUpdate()

    // 使用vuex记录是否已经加载过路由表
    if (!store.state.app.menuRouteLoaded) {
      const routerData = await getRoute() // 获取路由
      const menu = await useJwt.getMenu() // 获取菜单
      const menuArray = (menu.data.data || []).filter(item => !item.subheader)

      // vue2 写法
      routerData.push({
        path: '*',
        redirect: 'error-404',
        hidden: true,
      })
      routerData.forEach(item => router.addRoute(item))

      // 保存加载状态
      store.commit('menu/SET_MENU', menuArray)
      store.commit('app/MENU_ROUTE_LOADED', true)
      store.commit('app/UPDATE_NAV_TREE', routerData)

      next({ ...to, replace: true })

      return false
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next('/')
  }

  // 判断是否跳转到无权限页面
  if (store.state.menu.menu.length > 0 && to.path === '/dashboards/statistics') {
    next({ name: 'TransferPage', replace: true })

    return false
  }

  // 判断是否有有应用权限
  if (store.state.menu.menu.length === 0) {
    return next({ name: 'dashboard-statistics', replace: true })
  }

  return next()
})

export default router
