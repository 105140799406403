import Login from '@/views/Login.vue'

const state = {
  menu: [],
  sunMenu: [],
  flatteningSunMenu: [], // 扁平化的菜单数据为当前激活应用服务
}

function treeToArray(tree, rootName) {
  let arr = []
  tree.forEach(item => {
    arr.push({ ...item, rootName })
    if (item.children) {
      arr = arr.concat(treeToArray(item.children, rootName))
    }
  })

  return arr
}

const mutations = {
  SET_MENU(state, menu) {
    state.menu = menu
    const arr = []
    menu.forEach(item => {
      if (item.children) {
        arr.push(...treeToArray(item.children, item.route))
      }
    })

    // 扁平化所有数据
    state.flatteningSunMenu = arr
  },

  // 添加子菜单
  SET_SUN_MENU(state, sunMenu) {
    state.sunMenu = sunMenu
  },

  // 添加扁平化后的菜单
  SET_FLATTENING_SUN_MENU(state, flatteningSunMenu) {
    state.flatteningSunMenu = flatteningSunMenu
  },
}

const actions = {
  setMenu({ commit }, menu) {
    commit('SET_MENU', menu)
  },
  setSunMenu({ commit }, sunMenu) {
    return new Promise(resolve => {
      commit('SET_SUN_MENU', sunMenu.children)
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
