const serviceUrlPro = 'https://transform.xiaowutube.com/api'
const serviceUrlDev = 'http://172.16.24.200:9902' // 测试
const serviceUrlTest = 'http://172.16.24.200:9902' // 测试

const envServiceUrlMap = {
  development: serviceUrlDev,
  production: serviceUrlPro,
  buildTest: serviceUrlTest,
}
const serviceUrl = envServiceUrlMap[process.env.NODE_ENV]
const version = '1725957906056'
const defaultBucket = 'silverdawn-hz'
export default {
  serviceUrl,
  loginEndpoint: `${serviceUrl}/sso/doLoginByTicket`,
  userInfoEndpoint: `${serviceUrl}/sso/userInfo`,
  isLoginEndpoint: `${serviceUrl}/sso/isLogin`,
  ssoAuthUrlEndpoint: `${serviceUrl}/sso/getSsoAuthUrl`,
  logoutEndpoint: `${serviceUrl}/sso/logout`,
  routeEndpoint: `${serviceUrl}/base/route`,
  menuEndpoint: `${serviceUrl}/base/menu`,
  tokenType: 'Bearer',
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageVersionKeyName: 'version',
  defaultBucket,
  version,
}

// 出海通
