const themeConfig = {
  app: {
    name: '产品运营系统',
    logo: require('@/assets/images/logo.png'),
    isDark: false,
    isRtl: false,
    routeTransition: 'scroll-x-transition',
    skinVariant: 'semi-dark', // default, bordered, semi-dark
    contentWidth: 'full',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: process.env.NODE_ENV === 'production',
    verticalMenuAccordion: true,
    groupChildIcon: 'mdiCheckboxBlankCircleOutline',
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'hidden', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#9155FD',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
    dark: {
      primary: '#9155FD',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
  },
}

export default themeConfig
