import {
  getCurrentInstance, reactive, toRefs, watch,
} from '@vue/composition-api'

export function useRouter() {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })
  watch(() => vm.$route, r => { state.route = r })

  return {
    ...toRefs(state),
    router: vm.$router,
  }
}
