import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import directives from '@/directives'
import VXETable from 'vxe-table'
import { Cascader } from 'element-ui'
import XWUI from '@prettyy/ui'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import '@prettyy/ui/dist/css/index.css'
import 'element-ui/lib/theme-chalk/cascader.css'
import 'vxe-table/lib/style.css'

VXETable.config({
  width: 150,
  loadingText: '加载中...',
  table: {
    tooltipConfig: {
      enterable: true,
    },
  },
})

Vue.use(VXETable)
Vue.use(directives)
Vue.use(XWUI)
Vue.component(Cascader.name, Cascader) // 注册element ui 的 Cascader 组件

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
